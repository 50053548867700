@import '~antd/dist/antd.css';

.ant-tooltip-inner {
  background-color: #ED6D60;
  width: 50px;
  text-align: center;
  border-radius: 20px;
}
.ant-tooltip-arrow-content{
  background-color:  #ED6D60 !important;
}
.ant-slider-dot {
  background-color:  #ED6D60 !important;
  border-color: #ED6D60 !important;
}
.ant-slider-handle-2 {
  background-color:  #ED6D60 !important;
  border-color: #ED6D60 !important;
}
.ant-slider-dot-active {
  background-color:  #ED6D60 !important;
}
.ant-switch-checked {
  background-color:  #ED6D60 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ED6D60 !important; 
}
.ant-tabs-tab:hover {
  color: #F6B0AF !important; 
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #ED6D60;
  pointer-events: none;
}
.antd-timepicker-custom .ant-picker-input input {
  font-size: 15px;
  color: grey !important;
  font-family: "AvenirMedium";
  padding-top: 4px;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 15px
}

